// Generated by Framer (3fa6aa4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./YMIGkfVsq-0.js";

const cycleOrder = ["XGnkf1PUQ", "HoaFCJmky"];

const serializationHash = "framer-hT9vi"

const variantClassNames = {HoaFCJmky: "framer-v-1q4t5t6", XGnkf1PUQ: "framer-v-vwi2xn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Active: "HoaFCJmky", Inactive: "XGnkf1PUQ"}

const getProps = ({height, id, link, smooth, title, width, ...props}) => { return {...props, PGkyTrycd: title ?? props.PGkyTrycd ?? "Title", PvnQG2uF_: smooth ?? props.PvnQG2uF_ ?? true, S0KhFTFra: link ?? props.S0KhFTFra, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "XGnkf1PUQ"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string;smooth?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PGkyTrycd, S0KhFTFra, PvnQG2uF_, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "XGnkf1PUQ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={S0KhFTFra} openInNewTab={false} smoothScroll={PvnQG2uF_}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-vwi2xn", className, classNames)} framer-amzo5q`} data-framer-name={"Inactive"} layoutDependency={layoutDependency} layoutId={"XGnkf1PUQ"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({HoaFCJmky: {"data-framer-name": "Active"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1cgu0cl"} layoutDependency={layoutDependency} layoutId={"xVbKaSLE_"} style={{backgroundColor: "rgb(34, 34, 34)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, opacity: 0.4}} variants={{HoaFCJmky: {backgroundColor: "var(--token-7ccd0707-7819-4324-9597-14c6f94ecb9a, rgb(255, 128, 0))", opacity: 1}}}/><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 style={{"--font-selector": "R0Y7T3BlbiBTYW5zLTYwMA==", "--framer-font-family": "\"Open Sans\", \"Open Sans Placeholder\", sans-serif", "--framer-font-size": "20px", "--framer-font-weight": "600", "--framer-line-height": "1.4em", "--framer-text-color": "var(--extracted-1of0zx5, rgb(34, 34, 34))"}}>Title</motion.h2></React.Fragment>} className={"framer-mcvzce"} data-framer-name={"Title"} fonts={["GF;Open Sans-600"]} layoutDependency={layoutDependency} layoutId={"AcAWJO5sg"} style={{"--extracted-1of0zx5": "rgb(34, 34, 34)", "--framer-paragraph-spacing": "0px", opacity: 0.4}} text={PGkyTrycd} variants={{HoaFCJmky: {opacity: 1}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hT9vi.framer-amzo5q, .framer-hT9vi .framer-amzo5q { display: block; }", ".framer-hT9vi.framer-vwi2xn { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; min-width: 200px; padding: 0px; position: relative; text-decoration: none; width: 200px; }", ".framer-hT9vi .framer-1cgu0cl { flex: none; height: 2px; overflow: visible; position: relative; width: 2px; }", ".framer-hT9vi .framer-mcvzce { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-hT9vi.framer-vwi2xn { gap: 0px; } .framer-hT9vi.framer-vwi2xn > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-hT9vi.framer-vwi2xn > :first-child { margin-left: 0px; } .framer-hT9vi.framer-vwi2xn > :last-child { margin-right: 0px; } }", ".framer-hT9vi.framer-v-1q4t5t6 .framer-1cgu0cl { width: 30px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"],"constraints":[200,null,null,null]},"HoaFCJmky":{"layout":["fixed","auto"],"constraints":[200,null,null,null]}}}
 * @framerVariables {"PGkyTrycd":"title","S0KhFTFra":"link","PvnQG2uF_":"smooth"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerYMIGkfVsq: React.ComponentType<Props> = withCSS(Component, css, "framer-hT9vi") as typeof Component;
export default FramerYMIGkfVsq;

FramerYMIGkfVsq.displayName = "Item";

FramerYMIGkfVsq.defaultProps = {height: 28, width: 200};

addPropertyControls(FramerYMIGkfVsq, {variant: {options: ["XGnkf1PUQ", "HoaFCJmky"], optionTitles: ["Inactive", "Active"], title: "Variant", type: ControlType.Enum}, PGkyTrycd: {defaultValue: "Title", title: "Title", type: ControlType.String}, S0KhFTFra: {title: "Link", type: ControlType.Link}, PvnQG2uF_: {defaultValue: true, title: "Smooth", type: ControlType.Boolean}} as any)

addFonts(FramerYMIGkfVsq, [{explicitInter: true, fonts: [{family: "Open Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4iY1M2xLER.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})